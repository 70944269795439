div#root {
  display: flex;
  align-items: start;
   .container-fluid {
    padding: 0 40px !important;
}
  .sidebar {
    width: 90px;
    height: 100vh;
    background: #08415C;
    flex-shrink: 0;
    transition: 0.4s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    .closeBtn {
      height: 30px;
      width: 30px;
      background-color: #f4694b;
    }
    .dropdown-menu {
      a {
        color: #051c27 !important;
      }
    }
    .LinksWrpper {
      height: calc(100% - 80px);
      // overflow-y: scroll;
      // overflow-x: hidden;
      .accordion {
        height: 100%;
        overflow-y: auto;
        margin-bottom: 10px;
        scrollbar-width: none;
    }
      .LinksInner {
        ul {
          li {
              padding: 0 !important;
          }
          li:after {
            position: absolute;
            content: "";
            left: 0;
            top: 6px;
            height: 40px;
            width: 5px;
            background: #f4694b;
            opacity: 0;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
          // li:has(.active):after {
          //   opacity: 1;
          // }
        }
      }
      .link,
      .accordion-button {
        overflow: hidden;
        /* border-radius: 10px; */
        padding: 12px 15px;
        font-size: 10px;
        box-shadow: unset !important;
        /* font-size: 0; */
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        width: 100%;
        border: 0;
        font-family: 'Segoe UI' !important;
        span.icn {
            width: 100%;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
      .accordion-item:has(a.active) .accordion-header {
        background: #f4694b;
      }
      .accordion-item:has(a.active) button {
        background: #f4694b;
      }
      .accordion-item:has(a.active) .accordion-header::after {
        opacity: 1;
      }
      .accordion-header {
        overflow: hidden;
        // border-radius: 10px;

        .accordion-button {
          background: transparent;
          color: #fff;
          border-radius: 0 !important;
        }

        .accordion-button:after {
          position: absolute;
          content: "";
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
          transform-origin: center;
          background: #fff;
          height: 6px;
          width: 10px;
          clip-path: polygon(50% 100%, 0 0, 100% 0);
        }
        .accordion-button:not(.collapsed):after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
      .accordion-body {
  background: #002d43;
  a{
    font-size: 10px;
  line-height: 14px;
  padding: 9px 5px;
  color: #fff !important;
  display: block;
  text-align: center;
  }
  a:hover{
    background: #f4694b;
  
            }
        .subLinks {
          color: #fff;
          // list-style: disc;
          font-size: 0;
          a.active {
            font-weight: 700;
            color: #f4694b !important;
          }
         
        }
      }

      // a.link:after,
      // .accordion-header:after {
      //   position: absolute;
      //   content: "";
      //   right: 0;
      //   height: 60%;
      //   aspect-ratio: 1/1;
      //   background: #fff;
      //   border-radius: 50%;
      //   transform: translateX(50%) translateY(-50%);
      //   opacity: 0;
      //   top: 50%;
      // }
      a.link.active:after {
        opacity: 1;
      }
      a.link.active {
        font-weight: 700;
        background: #f4694b;
      }
      .cardCstm {
        border-radius: 20px;
        .top {
          .imgWrp {
            border: 2px solid #fff;
            padding: 3px;
            border-radius: 7px;
            img {
              border-radius: 7px;
              width: 40px;
              height: 40px;
            }
          }
        }
        .content {
          .btnWrp {
            .btn {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  main {
      width: calc(100% - 90px);
  }
}
.main-loader_{
 display: flex;
  justify-content: center; 
  align-items: flex-start; 
  position: fixed; 
  top: 50%; 
  left: 0; 
  right: 0; 
  bottom: 0;
  z-index: 9999; 
}
@media (max-width: 992px) {
  div#root { 
    .sidebar:not(.active) {
      transform: translateX(-100%);
    }
    .sidebar{
      z-index: 9999;
    }
    main {
      width: 100%;
      .container-fluid {
        padding: 0 12px !important;
    }
    }
  }
}


// css