@import url(./Variable.scss);
@import url(./Font.scss);
@import url(./Table.scss);

/**::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}*/
/* Track */
/**::-webkit-scrollbar-track {
  background: #ddd;
}*/
/* Handle */
/**::-webkit-scrollbar-thumb {
  background: #051C27;
}*/

body {
  font-family: 'Segoe UI' !important;
  font-size: 14px;
  line-height: 20px;
  color: var(--bs-bodyColor);
  /* word-break: break-word; */
}
h1,h2,h3,h4,h5,h6{
  font-family: 'Segoe UI' !important;
  /* font-family: 'Kelvinch' ; */
  color:#1A202C;

}
.text-white {
  color : #fefae0 !important
}

a, .themeLink  {
  text-decoration: none;
  color: var(--bs-hyperLinkColor) !important;
}
a:hover {
  /* color: var(--bs-buttonHover); */
  transition: 0.4s;
}
.progress-bar {
  background: var(--bs-buttonHover);
}
.ws-nowrap{
  white-space: nowrap !important;
}
.gap-10{
  gap: 10px !important;
}
.ws-normal{
  white-space: normal !important;
}
.fw-sbold {
  font-weight: 600 !important;
}
.modal-content {
  border-radius: 10px;
  border: 0;
}
.dropdown-toggle:after {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.underline{
  text-decoration: underline !important;
}
.SuccessText {
  color: #05cd99 !important;
}
.DangerText {
  color: #ee5d50 !important;
}
.WarningText {
  color: #ffce20 !important;
}
textarea.resizable {
  resize: vertical !important;
  min-height: 100px;
  max-height: 500px;
}
.tooltip-inner {
  background-color: #fefae0 !important;
  padding: 8px 15px;
  color: #000 !important; 
  font-weight: 500;
}
.tooltip .tooltip-arrow {
  display: none;
}
.CstmLabel {
  font-size: 12px;
  border: 1px solid #ddd;
  padding: 2px 8px;
  border-radius: 5px;
  font-weight: 500;
}
.GreenLabel.CstmLabel {
  background: #548B54;
  border-color: #548B54;
}
.commonBtnTabs .nav-link {
  color: #323232;
}
.commonBtnTabs .nav-link.active {
  background: #f4694b;
  color: #fff !important;
}
.FormbtnWrpper .btn {
  height: 45px;
  font-size: 12px;
  min-width: 120px;
}
.form-label {
  color: #4A5568;
  font-size: 14px;
}
.form-control {
  border-radius: 5px;
  height: 50px;
  font-size: 14px;
  color: #2D3748;
  box-shadow: unset !important;
}
textarea.form-control {
  height: auto;
  resize: none;
}
.form-control:focus {
    border-color: var(--bs-button);
}
.form-check {
  accent-color: var(--bs-button);
}
.d-flex > .imgWrp{
  flex-shrink: 0;
}
.theme-clr {
  color: #08415c !important;
}
.theme-blu {
  color: #2D3748 !important;
}
.theme-clr2 {
  color: #f4694b !important;
}
.rightMenu.dropdown .dropdown-menu {
  left: unset !important;
  right: 0 !important;
}
.dropdown-menu {
  border-radius: 10px;
  border: 1px solid #B6B5B7;
}
.dropdown-menu a {
  font-size: 12px;
}
.noLastBorder > *:not(:last-child) {
  border-bottom: 1px solid #ddd;
}
.commonBtn {
  height: 50px;
  background: var(--bs-button);
  border-color: var(--bs-button);
  white-space: nowrap;
}
.commonBtn svg path {
  fill: #fff;
}
.theme2BorderedBtn {
  border-radius: 5px;
  border: 1px solid #f4694b;
  background: #FFF0DB;
  color: #f4694b;
}
.commonBtn:hover{
  background: var(--bs-buttonHover) !important;
  border-color: var(--bs-buttonHover) !important;
}
.theme2BorderedBtn:hover span.icn svg path{
  fill: #fff;
}
html div#root .dropdown-item.active, html body div#root .dropdown-item:active {
  background: #000;
  color: #fff !important;
}
.dropdown-toggle.noAfter:after {
  display: none;
}
header.siteHeader .pagetitle {
  color: #323232;
  font-size: 22px;
}
.commonContent h4 {
  font-size: 18px;
}
.commonContent h3 {
  font-size: 20px;
}
.commonContent p {
  font-size: 14px;
  line-height: 24px;
}
div.SectionHeader .pagetitle {
  color: #323232;
  font-size: 18px;
}
.colorCards .cardCstm {
  border-radius: 10px;
}
.colorCards .cardCstm .imgWrp {
  height: 50px;
  width: 50px;
}
.gettingStarted {
  border-radius: 10px;
  border: 1px solid #08415C;
}
.gettingStarted .BulletList a {
  color: #323232;
  font-size: 12px;
}
.gettingStarted .BulletList a:hover span.icn svg path {
  fill: #405C40 ;
}
.gettingStarted .BulletList a:hover {
  text-decoration: underline;
  color: #405C40;
}
.commonCardBox {
  border-radius: 10px;
  border: 1px solid #ECECEC;
}
.commonCardBox .top h3 {
  font-size: 17px;
}
.btnSm span.icn svg {
  height: 14px;
  width: 14px;
}
.btnSm {
  font-size: 12px;
  height: 40px;
  padding: 0 10px;
}
.listView .imgWrp {
  aspect-ratio: 1/1;
}
.listView .content h3 {
  font-size: 16px;
}
.listView .content p {
  font-size: 13px;
}
.listView .content span.tym {
  font-size: 11px;
}
.listView .LinkCard:hover {
  background: #fefae0;
    border-color: #fefae0 !important;
}
.cstmInputCheckWrp {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  top: 2px;
}
.cstmInputCheck {
  border-radius: 2px;
  border: 1px solid #BABABA;
  background: #E8E8E8;
}
.cstmInputCheckWrp input.position-absolute {
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}
.cstmInputCheckWrp input.position-absolute:checked + .cstmInputCheck {
border-color: #f4694b;
background-color: #FFEBD0;
}
.cstmInputCheckWrp input.position-absolute:checked + .cstmInputCheck span.icn {
  display: flex !important;
}
.cstmInputCheckWrp.radio .cstmInputCheck {background: #DADADA !important;border-color: #DADADA !important;padding: 3px;}

.cstmInputCheckWrp.radio .cstmInputCheck span.icn {
    height: 100%;
    width: 100%;
    background: #f4694b;
}
.iconWithText .icn.position-absolute {
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.iconWithText input.form-control {
  padding-left: 34px;
}
.FilterWrp .iconWithText input, .FilterWrp .cstmCard {
    border-radius: 10px;
}
.commonCardBox .top button {
  font-size: 10px;
  border-radius: 5px;
  font-weight: 500;
  padding: 3px 8px;
}
.denotionList {
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  padding: 10px 15px;
}

.denotionList .denotion {
  width: 29px;
  height: 13px;
}
.denotionList p {
  font-size: 13px;
  color: #2D3748;
}
.SubCardBox {
  border-radius: 15px;
}
.SubCardBox .innerCard {
  border-radius: 10px;
}
.PricingCard .priceHead {
  font-size: 35px;
}

.PricingCard .priceHead span {
  font-size: 25px;
}
.PricingCard .commonBtn:hover {
  background: #f4694b !important;
  border-color: #f4694b !important;
}
.PricingCard {
  color: #1A202C;
}
.FormPop .modal-header .h4 {
  font-size: 16px;
  color: #323232;
  /* font-family: "kelvinch"; */
  font-family: 'Segoe UI' !important;

}
.FormPop .modal-dialog {
  max-width: 582px;
}
section.paymentSubs {
  min-height: 100vh;
}

section.paymentSubs .formWrpper {
  max-width: 450px;
  margin: 0 auto;
}
section.paymentSubs:after {
  position: absolute;
  content: "";
  top: 0;
  height: 100%;
  width: 50%;
  right: 0;
  background: #F8F8F8;
  z-index: -1;
}
.colorCards .cardCstm h3 {
  font-size: 16px;
  height: 42px;
}
.ColoredAcorrdion.accordion .accordion-button {
  background: transparent;
  border: 0 !important;
  box-shadow: unset;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: inherit;
  text-transform: uppercase;
  font-family: 'Segoe UI' !important;
  flex-wrap: wrap;
  font-size: 12px;
}
.ColoredAcorrdion.accordion .accordion-header button.accordion-button span.toggleBtn {
  height: 20px;
  width: 20px;
  border: 2px solid #000;
  border-radius: 5px;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.ColoredAcorrdion.accordion .accordion-header button.accordion-button.collapsed span.toggleBtn:after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  height: 80%;
  width: 2px;
  background: #000;
}

.ColoredAcorrdion.accordion .accordion-header button.accordion-button span.toggleBtn:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  width: 80%;
  height: 2px;
  background: #000;
}
.ColoredAcorrdion.accordion .accordion-header button.accordion-button {
  padding-right: 60px;
}
.ColoredAcorrdion.accordion .accordion-header, .ColoredAcorrdion.accordion .accordion-item{
  border-radius: 15px;
}
.ColoredAcorrdion.accordion .accordion-button:after{
  display: none;
}
.uploadFile {
  border-radius: 10px;
  border: 1px dashed #BBB;
}
.uploadFile input {
  left: 0;
  top: 0;
  z-index: 99;
  cursor: pointer;
  opacity: 0;
}
.CstmScale .cstmBox {
  min-width: 55px;
  height: 24px;
}
.CstmScale .cstmBox p {
  opacity: 0;
}
.CstmScale input {
  left: 0;
  top: 0;
  z-index: 9;
  opacity: 0;
  cursor: pointer;
}

.CstmScale input:checked + div.cstmBox {
  border: 2px solid #000;
}

.CstmScale input:checked + div.cstmBox p {
  opacity: 1;
}
.password-eye-icon {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 15px;
  padding-right: 10px;
  font-size: 1rem;
}

.table.recommendation-products td {
  white-space: normal !important;
}

.rbc-calendar .blocked-day,
.rbc-calendar .rbc-off-range-bg {
  background-color: #ddd;
  cursor: not-allowed !important;
}

.rbc-calendar .rbc-day-bg {
  cursor: pointer;
}

.answer-score {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.answer-score li {
  /* padding: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
}

.answer-score li.current-answer {
  color: #08415c;
  border: 1px solid #08415c;
  border-radius: 50%;
}

.custom-calendar-strip {
  display: flex;
}

.custom-calendar-strip .week-day-btn {
  /* border: 1px solid #ddd; */
  margin: 5px;
  padding: 5px 10px;
}

.custom-calendar-strip .selected-date {
  background-color: #ffe0b8;
  
}

.custom-calendar-strip .week-btn-container {
  display: flex;
  align-items: center;
}

.custom-calendar-strip .week-control-btn {
  padding: unset;
  padding: 10px;
  display: flex;
  border: 2px solid #999;
  border-radius: 50%;
}

.food-mood-table tr td {
  white-space: normal;
  word-wrap: break-word;
}

.fm-data-table .fm-data-cell {
  position: relative;
  vertical-align: top;
}

.toDo .top-bar {
  background-color: rgb(8, 65, 92);
  /* max-height: 50px; */
  left: 0;
  top: 0;
  /* bottom: 10px; */
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.toDo .top-bar .user-details p {
  color: #fff;
  font-size: 1.2em;
}

.my-log-list .feeling-card {
  background-color: #eee;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 5px;
  border-left: 4px solid #000;
}

.my-log-list .feeling-card.feeling-great{
  border-left-color: #7fbf7f;
}

.my-log-list .feeling-card.feeling-good{
  border-left-color: #a5d2a5;
}

.my-log-list .feeling-card.feeling-so_so{
  border-left-color: #bfbfbf;
}

.my-log-list .feeling-card.feeling-bad{
  border-left-color: #fea5a5;
}

.my-log-list .feeling-card.feeling-horrible{
  border-left-color: #fe7f7f;
}

.highlight {
  color: #c7254e !important;
  background-color: #f9f2f4 !important;
  border-radius: 4px !important;
}

.profiles-container .profile-link:hover {
 background-color: #eee;
}

.password-strength {
  margin-top: 0.25rem;
  font-size: .875em;
}
.password-strength .strength {
  font-weight: bold;
}
.loader-overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7); /* Black background with opacity */
  z-index: 9999; /* Specify a stack order in case you're using a different order for other elements */
  /* cursor: pointer; Add a pointer on hover */
}

.symptom-burden-graph ul.naq1-symptom-totals {
  columns: 3;
}


.todo-question .cstmInputCheckWrp:has(input:focus-visible) + p {
  border: 1px solid #747474 !important;
}

.todo-question .question-help {
  font-size: 1.1em;
  margin: 0;
}

.todo-form .todo-question .CstmScale ul {
  overflow: auto;
}

.download-pdf-dropdown .dropdown-item {
  padding: 0;
}

.download-pdf-dropdown .dropdown-item:active {
  background-color: #e9ecef !important;
}

.download-pdf-dropdown .dropdown-item a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px 10px;
}

.naqv2-reports ul.symptom-totals {
  columns: 2;
}

.naqv2-reports ul.symptom-totals .child-row {
  padding-left: 10px;
}

.is-multiline {
  white-space: pre-wrap;
}

.maintenance-page {
  text-align: center;
  width: 100%;
  height: 100vh;
  padding-top: 10rem;
  /* padding: 10rem 2rem;  */
  /* background-image: url("../Images/maintenance-new.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
}

.maintenance-page p {
  font-size: 16px;
  font-weight: bold;
}

/* Basic Styles */
.nta-time-picker {
  width: 100px;
  padding: 5px;
  font-size: 16px;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Hover State */
.nta-time-picker:hover {
  border-color: #999;
}

/* Focused State */
.nta-time-picker:focus {
  border-color: #007BFF;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Disabled State */
.nta-time-picker:disabled {
  background-color: #e9ecef;
  border-color: #ddd;
  color: #6c757d;
  cursor: not-allowed;
}

/* Additional Styling (optional) */
.nta-time-picker::-webkit-calendar-picker-indicator {
  /* background-color: #007BFF; */
  /* padding: 5px; */
  border-radius: 50%;
}

/* Custom Placeholder Color */
.nta-time-picker::placeholder {
  color: #888;
  opacity: 1;
}

.react-datepicker__tab-loop {
  position: fixed;
  z-index: 99;
}

.nta-banner {
  /* position: fixed; */
  width: 100%;
  z-index: 999;
  background: #fff;
  padding: 10px 10px 12px 10px;
  margin: 0;
  text-align: center;
  font-weight: 400;
  border-bottom: 1px solid #d7d7d7;
}

.nta-banner p {
  margin: 0 !important;
}

.announcement-item {
  padding: 1rem 1.25rem;
  background: rgb(249, 250, 255);
  color: rgb(43, 54, 116);
  border-radius: 15px;
  /* font-size: 14px !important; */
  font-weight: 700;
}

.announcements .ColoredAcorrdion.accordion .accordion-button {
  text-transform: revert;
  /* font-size: 14px; */
  font-weight: 700;
  display: block;
}

.announcements .new-badge {
  font-size: 28px;
  top: 5px;
  right: 5px;
  color: rgb(255, 87, 87);
  font-weight: 700;
  position: absolute;
}


@media (max-width: 1400px) {
  body {
    font-size: 12px;
  }
}
@media (max-width: 992px){
  section.paymentSubs:after {
    width: 100%;
  }
}
@media (max-width: 575px){
  header .commonBtn {
    font-size: 0;
}
.DocFormBox {
  flex-wrap: wrap;
}
header .commonBtn span.icn {
    margin-right: 0 !important;
}
header.siteHeader .pagetitle{
  font-size: 18px;
}
.commonContent h3 {
  font-size: 16px;
}
}
/* end css */