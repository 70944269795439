.sidebar {
    .profileDropdown{
        .dropdown-toggle {
            height: 60px;
            width: 60px;
            border-radius: 5px;
            // border: 2px solid #fff;
            img {
                border: 2px solid #f4694b;
                border-radius: 5px;
            }
            span {
                height: 30px;
                width: 30px;
                background: #f4694b;
                right: -5px;
                bottom: 0;
                transform: translateY(50%);
                border-radius: 7px;
                svg path {
                    fill: #fff;
                }
            }
        }
    }
    .dropdown-menu{
        background-color: #fefae0;
        border-radius: 5px;
        right: 0 !important;
        left: unset !important;
        transform: translateX(100%) !important;
    a {
        color: #051c27 !important;
    }
    }
}

div#root .sidebar .LinksWrpper .link-disabled, div#root .sidebar .LinksWrpper .accordion-button {
    border: 0;
    box-shadow: none!important;
    flex-wrap: wrap;
    font-size: 10px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 15px;
    text-align: center;
    width: 100%;
    flex-direction: column;
}