.ProgressBarBox {
    top: 90px !important;
    li.active * {
        color: #f4694b !important;
      }
}
@media (max-width: 992px){
    .ProgressBarBox {
        order: -1;
        position: unset !important;
    }
}