table.table th.thDenotion {
    min-width: 80px;
    white-space: normal;
    max-width: 80px;
}
table.table tbody tr:hover{
    background: #fffef7;
}
table.table thead {
    background: #f9f9f9;
}   
table.table th, table.table td{
    vertical-align: middle;
    max-width: 300px;
    color: #666;
    font-size: 13px;
    white-space: nowrap;
  }
table.table .tableDenotion {
    height: 30px;
}
table.table .CstmLabel {
    font-size: 12px;
    border: 1px solid #ddd;
    padding: 2px 8px;
    border-radius: 5px;
    font-weight: 500;
}