section.AuthLayout  {
    height: 100vh;
    font-size: 14px;
    .imgWrpper {
        top: 0;
        left: 0;
        z-index: -1;
    }
    .FormInner::-webkit-scrollbar{
        display: none;
    }
    .FormInner {
        max-height: 100vh;
        overflow-y: scroll;
        overflow-x: hidden;
        border-radius: 20px;
        .OtpCstm input {
            height: 55px;
            width: 55px;
            border-radius: 5px;
            border: 1px solid #ddd;
            outline: 0 !important;
        }
        .top {
            h2 {
                font-size: 30px;
                color: #323232;
            }
        }
    }
  }
  @media (max-width: 992px){
    section.AuthLayout  {
         .imgWrpper{
        width: 100%;
    }
  }
  }